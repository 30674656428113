import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Moment from 'react-moment';
import { Layout, Wrapper, SliceZone, SEO, Header, ListingCard, ListingAgency, Title, Section } from "../../components";
import website from '../../../config/website';
import { Hero, HeroInner, Headline, HeroBrightner, StyledWrapper, HeadWrapper, HeroThumb } from './Elements';
import Img from "gatsby-image";

const Agence = ({ data: { prismicAgency, posts, agencies }, location }) => {
  const { data } = prismicAgency;

  return (
    <Layout customSEO>
      <SEO
        title={`${data.name.text} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={data.shortdesc.text}
        banner={data.image.url}
        node={prismicAgency}
        article
      />
      <Header light />
      <Hero style={{ backgroundImage: `url(${data.image.url})` }}>
        {/*<HeroBrightner />*/}
        <HeroInner>
          <HeadWrapper>
            <Headline>
              <h1>{data.name.text}</h1>
            </Headline>
            {/*<div dangerouslySetInnerHTML={{ __html: data.shortdesc.html }} />*/}
          </HeadWrapper>
        </HeroInner>
      </Hero>
        <SliceZone allSlices={data.body} />
        <Section>
      <StyledWrapper>

      {/*<Link className="button" to="/contact">Nous contacter</Link>*/}
        {/*<h3 style={{ marginTop: '4rem' }}>Nos autres offres</h3>*/}
        {/*<ListingCard posts={posts.edges} />*/}
        <Title title="<h2>Nos autres agences</h2>" />
        <ListingAgency agencies={agencies.edges} />
      </StyledWrapper>
        </Section>
    </Layout>
  );
};

export default Agence;

Agence.propTypes = {
  data: PropTypes.shape({
    prismicAgency: PropTypes.object.isRequired,
    posts: PropTypes.object.isRequired,
    agencies: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query AgenceBySlug($uid: String!) {
      prismicAgency(uid: { eq: $uid }) {
      uid
      data {
        name {
          html
          text
        }
        shortdesc {
          text
          html
        }

        image {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            url
            alt
        }
          

        body {
          ... on PrismicAgencyBodyTextpage {
            slice_type
            id
            primary {
                title1 {
                    text
                    html
                }
              text {
                html
                text
              }
            }
          }
#          ... on PrismicAgenceBodyQuote {
#            slice_type
#            id
#            primary {
#              quote {
#                html
#                text
#              }
#              author
#            }
#          }
#          ... on PrismicAgenceBodyVideo {
#            slice_type
#            id
#            primary {
#              video {
#                html
#              }
#            }
#          }
#          ... on PrismicAgenceBodyListeDeProfiles {
#              id
#              items {
#                  person_selected {
#                      uid
#                      document {
#                          data {
#                              name
#                              surname
#                              description {
#                                  html
#                                  text
#                              }
#                              image {
#                                  thumb {
#                                      localFile {
#                                          childImageSharp {
#                                              fluid(maxWidth: 400, quality: 90) {
#                                                  ...GatsbyImageSharpFluid_withWebp
#                                              }
#                                          }
#                                      }
#                                  }
#                              }
#                          }
#                          uid
#                      }
#                  }
#              }
#              slice_type
#              primary {
#                  selectedtext {
#                      html
#                      text
#                  }
#              }
#          }
#          ... on PrismicAgenceBodyListeImageTexte {
#              id
#              slice_type
#              items {
#                  image {
#                      mini {
#                          localFile {
#                              childImageSharp {
#                                  fluid(maxWidth: 200, quality: 90) {
#                                      ...GatsbyImageSharpFluid_withWebp
#                                  }
#                              }
#                          }
#                      }
#                  }
#                  text {
#                      html
#                  }
#                  title {
#                      html
#                  }
#              }
#          }
#          ... on PrismicAgencyBodyImage {
#            slice_type
#            id
#            primary {
#              image {
#                localFile {
#                  childImageSharp {
#                    fluid(maxWidth: 800, quality: 90) {
#                      ...GatsbyImageSharpFluid_withWebp
#                    }
#                  }
#                }
#              }
#            }
#          }
          ... on PrismicAgencyBodySecteursDActivite {
              id
              slice_type
              primary {
                  title {
                      html
                      text
                  }
              }
              items {
                  area {
                      document {
                          uid
                          data {
                              thumb {
                                  localFile {
                                      childImageSharp {
                                          fluid(maxWidth: 200, quality: 90) {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                      }
                                  }
                                  alt
                              }
                              secteur_d_activite {
                                  html
                                  text
                              }
                          }
                      }
                  }
              }
          }
          ... on PrismicAgencyBodyListeLiensOffres {
              id
              primary {
                  titre_du_bloc {
                      html
                      text
                  }
              }
              items {
                  offre {
                      document {
                          data {
                              thumb {
                                  min{
                                      localFile {
                                          childImageSharp {
                                              fluid(maxWidth: 200, quality: 90) {
                                                  ...GatsbyImageSharpFluid
                                              }
                                          }
                                      }
                                  }
                              
                              full{
                                  localFile {
                                      childImageSharp {
                                          fluid(maxWidth: 312, quality: 90) {
                                              ...GatsbyImageSharpFluid
                                          }
                                      }
                                  }
                              }
                              }
                              shortdesc {
                                  text
                              }
                              title {
                                  text
                              }
                              liste_description {
                                  html
                                  text
                              }
                          }
                          uid
                      }
                  }
              }
              slice_type
          }
          ... on PrismicAgencyBodyDomainesDIntervention {
              id
              primary {
                  title {
                      html
                      text
                  }
                  bgcolor
                  display
              }
              slice_type
              items {
                  domaine {
                      document {
                          uid
                          data {
                              title {
                                  html
                                  text
                              }
                              image {
                                  localFile {
                                      childImageSharp {
                                          fluid(maxWidth: 200, quality: 90) {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                      }
                                  }
                              }
                              description_du_domaine {
                                  html
                                  text
                              }
                          }
                      }
                  }
              }
          }
        }
      }
    }
    posts: allPrismicPost(limit: 3, sort: { fields: [data___date], order: DESC }, filter: { uid: { ne: $uid } }) {
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          data {
            title {
              text
            }
            shortdesc {
              text
            }
            main {
              full {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "DD.MM.YYYY")
            categorie {
              document {
                uid
                data {
                  name
                  color
                }
              }
            }
            authors {
              author {
                id
                uid
                document {
                  data {
                    name
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
      agencies: allPrismicAgency (sort: { fields: [first_publication_date], order: ASC }, filter: { uid: { ne: $uid } }){
          edges {
              node {
                  uid
                  first_publication_date
                  data {
                      name{
                          text
                          html
                      }
                      adresse_1
                      cp
                      image {
                          alt
                          thumb {
                              localFile {
                                  childImageSharp {
                                      fluid(maxWidth: 300, quality: 90) {
                                          ...GatsbyImageSharpFluid_withWebp
                                      }
                                  }
                              }
                          }
                      }

                      ville
                      phone
                  }
              }
          }
      }
  }
`;
